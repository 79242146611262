import { convertUnknownToError, reportErrorToBugsnag } from '@air/utils-error';

import {
  isIncorrectUsernameOrPasswordError,
  isUserDoesNotExistError,
  isUserDoesNotHavePasswordError,
} from './loginErrorUtils';

export const getLoginError = ({ email, error }: { email: string; error: unknown }) => {
  const _error = convertUnknownToError(error);

  if (isUserDoesNotHavePasswordError(_error)) {
    return 'No password on this account.';
  }

  if (isIncorrectUsernameOrPasswordError(_error)) {
    return 'Incorrect username or password.';
  }

  if (isUserDoesNotExistError(_error)) {
    return 'User does not exist.';
  }

  reportErrorToBugsnag({
    error,
    context: 'Failed to login',
    metadata: {
      Data: { emailFromAttemptedLogin: email },
    },
  });

  return _error.message;
};
