const pxToRem = (value: number): string => `${value / 16}rem`;

export type FontSizeName =
  | 'inherit'
  | 'initial'
  | 'unset'
  | 8
  | 10
  | 12
  | 14
  | 16
  | 18
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 48
  | 56
  | 64
  | 72;

const fontSizes: { [key in FontSizeName]: string } = {
  inherit: 'inherit',
  initial: 'initial',
  unset: 'unset',
  8: pxToRem(8),
  10: pxToRem(10),
  12: pxToRem(12),
  14: pxToRem(14),
  16: pxToRem(16),
  18: pxToRem(18),
  20: pxToRem(20),
  24: pxToRem(24),
  28: pxToRem(28),
  32: pxToRem(32),
  36: pxToRem(36),
  40: pxToRem(40),
  48: pxToRem(48),
  56: pxToRem(56),
  64: pxToRem(64),
  72: pxToRem(72),
};

export default fontSizes;
