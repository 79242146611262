export type LetterSpacingName =
  | 'inherit'
  | 'initial'
  | 'normal'
  | 'unset'
  | 8
  | 9
  | 10
  | 12
  | 14
  | 16
  | 18
  | 20
  | 24
  | 32
  | 40
  | 48
  | 56
  | 64
  | 72;

const letterSpacing: { [key in LetterSpacingName]: string } = {
  inherit: 'inherit',
  initial: 'initial',
  normal: 'normal',
  unset: 'unset',
  8: '0.09em',
  9: '0.02em',
  10: '0.01em',
  12: '0',
  14: '-0.006em',
  16: '-0.011em',
  18: '-0.014em',
  20: '-0.017em',
  24: '-0.019em',
  32: '-0.021em',
  40: '-0.022em',
  48: '-0.022em',
  56: '-0.022em',
  64: '-0.022em',
  72: '-0.022em',
};

export default letterSpacing;
