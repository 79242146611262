/**
 * Grabs all the URL parameters in the url
 * and returns it in a object.
 */
export const getUTMParameters = (url: string, prefix?: string) => {
  const values: { [key: string]: string } = {};
  const params = new URLSearchParams(url);

  params.forEach((value, key) => {
    const keyName = prefix ? `${prefix}_${key}` : key;

    if (key.includes('utm_')) {
      return (values[keyName] = value);
    }
  });

  return values;
};
