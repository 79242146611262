const sizes = {
  small: 560,
  medium: 700,
  large: 920,
  xlarge: 1104,
  xxlarge: 1174,
};

const breakpoints = Object.values(sizes)
  .sort((a, b) => a - b)
  .map((size) => `${size}px`);

export default breakpoints;
